@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: 'zilla';
        src: url(../fonts/ZillaSlab-Regular.otf);
    }

    html {
        font-family: 'zilla';
        font-size: 16pt;
    }

    h1 {
        @apply text-xl md:text-2xl lg:text-4xl text-blue-800;
    }

    h2 {
        @apply text-red-700 md:text-2xl lg:text-3xl;
    }

    h3 {
        @apply text-sm md:text-lg text-gray-500;
    }

    h4 {
        @apply text-sm md:text-lg text-blue-700;
    }

    a {
        @apply text-blue-600;
    }

    a:hover {
        @apply text-blue-400;
    }

    button {
        @apply bg-blue-500 text-white p-2 md:px-10 md:py-2 rounded md:rounded-md border-blue-900 transition duration-500 ease-in-out hover:bg-blue-400 transform hover:-translate-y-1 hover:scale-110 
    }

    #heroContainer {
        font-size: 24pt;
    }

    #X { @apply text-red-600; }
    #O1 { @apply text-green-600 }
    #S { @apply text-yellow-300 }
    #O2 { @apply text-blue-500 }

    .mailto {
        animation: fadeInUp;
        animation-delay: 4.5s;
    }

    .logo {
        height: 30px;
        width: 30px;
        background-image: url("../images/xoso-logo-2.png");
        background-color: transparent;
        background-size: cover;
    }
}
